import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
    Layout,
    Button,
    Divider,
    Typography,
    notification,
    message,
    Select,
    Tag,
    // Tooltip,
    Row,
    Col,
    Modal as ModalAntd,
    Checkbox,
} from 'antd';
import {
    EditOutlined,
    CaretDownOutlined,
    EnvironmentOutlined,
    PlusOutlined,
    // FilterOutlined,
    SearchOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import ListRestaurant from '../../../components/Client/ListRestaurant';
import Footer from '../../../components/Client/Footer';
import HeaderTop from '../../../components/General/HeaderTop';
import Modal from '../../../components/General/Modal';
import AddressForm from '../../../components/Client/AddressForm';
import { getAccessTokenApi } from '../../../api/auth';
import { getAllOrdersFiltersApi } from '../../../api/order';
import { updateUserApi } from '../../../api/user';
import {
    getCategoriesApi,
    getCompaniesWithImageApi,
} from '../../../api/company';
import useUser from '../../../hooks/useUser';
import useAuth from '../../../hooks/useAuth';
import useOrder from '../../../hooks/useOrder';
import logoControlB from '../../../assets/img/png/logoControlB.png';
import { getFullAddress } from '../../../utils/general';
import {
    COMPANY_ID,
    COMPANY_IMAGE,
    COMPANY_NAME,
    USER_PATH,
} from '../../../utils/constants';
import {
    getPlaces,
    selectPlace,
    deleteAddressClient,
} from '../../../api/places';
import { messageError, userLogOut } from '../../../utils/general';

import './Home.scss';

export default function Home({ history }) {
    const isMounted = useRef(true);
    const [activeOrderID, setActiveOrderID] = useState(false);
    const [restaurants, setRestaurants] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [restaurantsFilter, setRestaurantsFilter] = useState([]);
    // const [restaurantsName, setRestaurantsName] = useState([]);
    const { person, setPerson } = useUser();
    const { user } = useAuth();
    const { orderGeneral, reset } = useOrder();
    const { shortName } = person;
    const [loadingPage, setLoadingPage] = useState(true);
    const fullAddress = useMemo(() => getFullAddress(person), [person]);
    const [addressUser, setAddressUser] = useState(fullAddress);
    const [isAddressNull, setIsAddressNull] = useState(
        addressUser === null ? true : false
    );
    const [isModalVisibleAddress, setIsModalVisibleAddress] = useState(false);
    const [isModalVisibleFilters, setIsModalVisibleFilters] = useState(false);
    const { Paragraph } = Typography;
    const [places, setPlaces] = useState(null);
    const [isModalVisibleEditAddress, setIsModalVisibleEditAddress] =
        useState(false);
    const token = getAccessTokenApi();
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        if (orderGeneral.orderLinesList.length > 0) {
            message.info('Su carrito se ha vaciado.');
        }
        localStorage.setItem(USER_PATH, '/');
        reset();
        localStorage.removeItem(COMPANY_ID);
        localStorage.removeItem(COMPANY_IMAGE);
        localStorage.removeItem(COMPANY_NAME);
        getAddress();
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (person) {
            const addressPerson = getFullAddress(person);
            setAddressUser(addressPerson);
            setIsAddressNull(addressPerson === null ? true : false);
        }
    }, [person]);

    useEffect(() => {
        getCategoriesApi().then((response) => {
            let categories = [
                {
                    value: 'all',
                    label: 'Todas las categorias',
                },
            ];

            if (response) {
                response.forEach((category) => {
                    categories.push({
                        value: category.name,
                        label: category.name,
                    });
                });
                setCategoryOptions(categories);
            }
        });
    }, []);

    const getAddress = () => {
        getPlaces(token).then((response) => {
            if (response === undefined) {
                const msg = messageError();

                notification['error']({
                    message: msg,
                });
                return;
            }
            if (response.statusCode === 200) {
                setPlaces(response.result);
            }
        });
    };
    async function getRestaurantAndOrder() {
        const clientId =
            user[
                'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
            ];
        const token = getAccessTokenApi();
        const filter = `isTestingCompany=${false}&isShownInPool=${true}&isActive=${true}`;
        const filterOrders = `?&ClientId=${clientId}&Status=1&Status=2&Status=3&Status=4&Status=5`;

        let [resultOrder, restaurant] = await Promise.all([
            getAllOrdersFiltersApi(token, filterOrders),
            // getActiveOrderApi(token),
            getCompaniesWithImageApi(token, filter),
        ]);
        setLoadingPage(false);

        if (isMounted.current) {
            if (resultOrder) {
                if (resultOrder.statusCode === 200) {
                    if (resultOrder.result) {
                        setActiveOrderID(resultOrder.result.length);
                    }
                }
            }

            if (restaurant) {
                if (restaurant.statusCode) {
                    notification['error']({
                        message:
                            'Ocurrio un error al cargar los restaurantes, intentelo mas tarde.',
                    });
                } else {
                    // let arrayName = [];
                    // restaurant.forEach((rest) => {
                    //     const nameRest = rest.name;
                    //     arrayName.push({ value: nameRest });
                    // });
                    restaurant.forEach((rest) => {
                        let allCats = [];

                        rest.companyCategories.forEach((cat) => {
                            allCats.push(cat.name);
                        });
                        rest['categories'] = allCats;
                    });
                    setRestaurants(restaurant);
                    setRestaurantsFilter(restaurant);
                }
            }
        }
    }

    useEffect(() => {
        getRestaurantAndOrder();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    //ACTUALIZA DIRECCION DEL CLIENTE
    const updateAddressClient = async ({
        address,
        neighborhood,
        city,
        zipCode,
        state,
    }) => {
        if (
            address !== undefined ||
            neighborhood !== undefined ||
            city !== undefined ||
            zipCode !== undefined ||
            state !== undefined
        ) {
            if (
                addressUser !==
                `${address} ${neighborhood} ${city} ${zipCode} ${state} `
            ) {
                const token = getAccessTokenApi();

                const data = {
                    Id: user[
                        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
                    ],
                    // Name: nameClient,
                    UserType: 3,
                    Address: address,
                    Neighborhood: neighborhood,
                    city: city,
                    ZipCode: zipCode.toString(),
                    State: state,
                    Email: user[
                        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
                    ],
                    // PhoneNumber: phoneNumberClient,
                };

                let validAddress = true;

                // if (state !== infoCompany.State) {
                //     validAddress = false;
                // }
                const result = await updateUserApi(token, data);

                if (result === undefined) {
                    message.error(
                        'Ocurrió un error al guardar su dirección, Intentelo mas tarde'
                    );
                } else {
                    if (result.statusCode === 200) {
                        if (validAddress) {
                            message.success('Se ha actualizado su dirección');
                        } else {
                            message.error(
                                'Su ubicación no se encuentra cerca del restaurante'
                            );
                        }

                        if (isAddressNull === true) {
                            setIsAddressNull(false);
                        }
                    } else {
                        message.error(
                            'Ocurrió un error al guardar su dirección'
                        );
                    }
                }
            }
        }
    };
    const checkAddres = () => {
        if (places?.length === 3) {
            notification['error']({
                message: 'Solo puedes tener 3 direcciones',
            });
            return;
        } else {
            setIsModalVisibleAddress(true);
        }
    };

    function tagRender(props) {
        const { label, closable, onClose } = props;

        return (
            <Tag
                color="blue"
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
            >
                {label}
            </Tag>
        );
    }

    function filterByCat(obj, value) {
        const intersection = obj.categories.filter((element) =>
            value.includes(element)
        );

        if (intersection.length > 0) {
            return true;
        } else {
            return false;
        }
        // if (obj.categories.includes(value)) {
        //     return true;
        // } else {
        //     return false;
        // }
    }

    const selectCategory = (value) => {
        if (value) {
            if (value.includes('all') || value.length === 0) {
                setRestaurantsFilter(restaurants);
            } else {
                const restaurantFilterCat = restaurants.filter((obj) =>
                    filterByCat(obj, value)
                );
                setRestaurantsFilter(restaurantFilterCat);
            }
        }
        setOpenModal(false);

        // if (value === 'all') {
        //     setRestaurantsFilter(restaurants);
        // } else {
        //     const restaurantFilterCat = restaurants.filter((obj) =>
        //         filterByCat(obj, value)
        //     );
        //     setRestaurantsFilter(restaurantFilterCat);
        // }
    };
    const callModal = () => {
        if (!openModal) {
            setOpenModal(true);
        }
    };

    return (
        <>
            <Layout className="home">
                <HeaderTop
                    logoCompany={true}
                    title={`¡Hola, ${shortName}!`}
                    imageCompany={logoControlB}
                />
                <div className="home__container">
                    <Button
                        icon={<PlusOutlined />}
                        type="link"
                        size="large"
                        block
                        onClick={() => checkAddres()}
                    >
                        Agregar dirección
                        <CaretDownOutlined />
                    </Button>

                    {!isAddressNull && (
                        <>
                            <Divider>Entregar en</Divider>
                            <Row className="cener-content">
                                <Paragraph className="text-center">
                                    {' '}
                                    <EnvironmentOutlined
                                        style={{ color: '#d9363e' }}
                                    />{' '}
                                    {addressUser}
                                </Paragraph>
                                {places !== null && (
                                    <Button
                                        className="align-icon"
                                        icon={
                                            <EditOutlined
                                                style={{ fontSize: 22 }}
                                            />
                                        }
                                        type="link"
                                        size="large"
                                        onClick={() =>
                                            setIsModalVisibleEditAddress(true)
                                        }
                                    >
                                        {' '}
                                    </Button>
                                )}
                            </Row>

                            <Divider />
                        </>
                    )}
                    <div className="home__container-search">
                        <Row>
                            <Col span={24}>
                                <Link to={'/search'}>
                                    <Button
                                        className="btn-search-restaurant"
                                        type="text"
                                        icon={<SearchOutlined />}
                                        block
                                    >
                                        ¿ Buscas algún restaurante ?
                                    </Button>
                                </Link>
                            </Col>
                            <Col span={24}>
                                <Divider>Busca por categorias</Divider>
                                <Select
                                    mode="multiple"
                                    // mode='tags'
                                    // showArrow
                                    showSearch
                                    tagRender={tagRender}
                                    style={{ width: '100%' }}
                                    options={categoryOptions}
                                    placeholder="Seleccione una categoría"
                                    optionFilterProp="label"
                                    filterOption
                                    className="select-categories"
                                    onChange={selectCategory}
                                    // onSelect={selectCategory}
                                    // onSearch={selectCategory}
                                    allowClear
                                    onClear={() =>
                                        setRestaurantsFilter(restaurants)
                                    }
                                    onBlur={() => setOpenModal(false)}
                                    open={openModal}
                                    onClick={() => callModal()}
                                    onFocus={() => setOpenModal(true)}
                                />
                            </Col>
                            {/* <Col span={6} style={{ textAlign: 'right' }}>
                                <Tooltip title="Filtros">
                                    <Button
                                        danger
                                        type="ghost"
                                        shape="circle"
                                        icon={<FilterOutlined />}
                                        onClick={() =>
                                            setIsModalVisibleFilters(true)
                                        }
                                    />
                                </Tooltip>
                            </Col> */}
                        </Row>
                        {/* <AutoComplete
                            allowClear
                            // options={restaurantsName}
                            style={{ width: '100%' }}
                            onSelect={onSelect}
                            onSearch={onSearch}
                            placeholder="Buscar restaurante..."
                            backfill
                            open
                            // filterOption={(inputValue, option) =>
                            //     option.value
                            //         .toUpperCase()
                            //         .indexOf(inputValue.toUpperCase()) !== -1
                            // }
                        /> */}
                    </div>
                    <ListRestaurant
                        restaurants={restaurantsFilter}
                        // restaurants={restaurants}
                        loadingPage={loadingPage}
                        history={history}
                    />
                </div>
            </Layout>
            <Footer
                showOrder
                activeOrderID={activeOrderID}
                isHome={true}
                showHome
            />
            <Modal
                isVisible={isModalVisibleAddress}
                setIsVisible={setIsModalVisibleAddress}
                title={'Dirección de entrega'}
                footer={false}
                destroyOnClose
            >
                <AddressForm
                    updateAddressClient={updateAddressClient}
                    setAddressUser={setAddressUser}
                    setIsModalVisibleAddress={setIsModalVisibleAddress}
                />
            </Modal>
            <Modal
                isVisible={isModalVisibleEditAddress}
                setIsVisible={setIsModalVisibleEditAddress}
                title={'Editar dirección de entrega'}
                footer={false}
                destroyOnClose
            >
                <PlacesModal
                    places={places}
                    selectedAddress={selectedAddress}
                    setSelectedAddress={setSelectedAddress}
                    setAddressUser={setAddressUser}
                    setIsModalVisibleEditAddress={setIsModalVisibleEditAddress}
                    getAddress={getAddress}
                    updateAddressClient={updateAddressClient}
                    person={person}
                    setPerson={setPerson}
                />
            </Modal>
            <Modal
                isVisible={isModalVisibleFilters}
                setIsVisible={setIsModalVisibleFilters}
                title={''}
                footer={false}
                destroyOnClose
            >
                Filtrar
            </Modal>
        </>
    );
}
function PlacesModal(props) {
    const {
        places,
        setSelectedAddress,
        setAddressUser,
        setIsModalVisibleEditAddress,
        getAddress,
        updateAddressClient,
        person,
        setPerson,
    } = props;
    const token = getAccessTokenApi();
    const { confirm } = ModalAntd;

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
        getAddress();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const onCheckBoxChanged = (address) => {
        setSelectedAddress(address.id);
        selectPlace(token, address.id).then((response) => {
            if (response === undefined) {
                const msg = messageError();

                notification['error']({
                    message: msg,
                });
                return;
            }
            if (response.statusCode === 200) {
                const addressComplete = `${address.address} ${address.neighborhood} ${address.city} ${address.zipCode} ${address.state} `;
                const addressCompleteJSON = {
                    alias: address.alias,
                    address: address.address,
                    neighborhood: address.neighborhood,
                    city: address.city,
                    zipCode: address.zipCode,
                    state: address.state,
                };
                setAddressUser(addressComplete);
                updateAddressClient(addressCompleteJSON);
                setIsModalVisibleEditAddress(false);
                setPerson({
                    ...person,
                    address: address.address,
                    neighborhood: address.neighborhood,
                    city: address.city,
                    zipCode: address.zipCode,
                    state: address.state,
                });
            }
        });
    };
    const deleteAddress = (address) => {
        if (address.address === person.address) {
            notification['error']({
                message:
                    'No puedes eliminar la dirección que se encuentra activa',
            });
        } else {
            confirm({
                title: 'Eliminar dirección',
                content: `¿Estas seguro que quieres eliminar la dirección ${address.address} ${address.city} ${address.zipCode}
                ${address.state}?`,
                okText: 'Eliminar',
                okType: 'primary',
                cancelText: 'Cancelar',
                onOk() {
                    deleteAddressClient(token, address.id).then((response) => {
                        if (response === undefined) {
                            const msg = messageError();

                            notification['error']({
                                message: msg,
                            });
                            return;
                        }
                        if (response.statusCode === 200) {
                            notification['success']({
                                message: 'Dirección eliminada exitosamente',
                            });
                            getAddress();
                        }
                    });
                },
            });
        }
    };
    return (
        <>
            {places.map((ele, index) => {
                return (
                    <div className="text-center" key={ele.id}>
                        <Row className="cener-content">
                            <Checkbox
                                onChange={() => onCheckBoxChanged(ele)}
                                defaultChecked={ele.isActive}
                                checked={ele.isActive}
                            ></Checkbox>
                            <p style={{ marginLeft: 5 }}>
                                <span className="text-style">{ele.alias}</span>
                                {ele.address} {ele.city} {ele.zipCode}{' '}
                                {ele.state}
                            </p>
                            <Button
                                className="align-icon"
                                icon={<DeleteOutlined />}
                                type="link"
                                size="large"
                                onClick={() => deleteAddress(ele)}
                            ></Button>
                        </Row>
                    </div>
                );
            })}
        </>
    );
}
